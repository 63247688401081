<template>
  <div class="checkout">
    <div class="container">
      <base-heading align="center" bold="bold" class="checkout__heading">
        {{ $t('checkout.heading') }}
      </base-heading>

      <div class="checkout__layout">
        <checkout-items :cartList="cartList" :isCartEmpty="isCartEmpty" :extraList="extraList" :promoCode="promoCode"/>
        <checkout-summary :isCartEmpty="isCartEmpty" @click="handleOrder"
                          :setPromoCode="setPromoCode" ref="summary"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import payment from '@/api/services/payment';
import course from '@/api/services/course';
import * as Sentry from '@sentry/vue';

export default {
  name: 'Checkout',
  data: () => ({
    orders: [],
    userCourses: [],
    promoCode: '',
    extraList: [],
  }),
  watch: {
    orders() {
      const targetLength = this.coursesIDs.length;
      const orders = new Set(this.orders);
      if (orders.size === targetLength) {
        this.makeOrderPay();
      }
    },
  },
  computed: {
    ...mapState({
      cartList: (state) => state.cart.cartList,
      isLogged: (state) => state.auth.token,
    }),
    ...mapGetters({
      isDataFilled: 'auth/isDataFilled',
    }),
    isCartEmpty() {
      return this.cartList.length === 0;
    },
    coursesIDs() {
      if (this.cartList) {
        return this.cartList.map((item) => item.id);
      }

      return null;
    },
  },
  methods: {
    setPromoCode(promoCode) {
      this.promoCode = promoCode;
    },
    async makeOrderRequest(courseID) {
      const findedOrder = this.findExistingOrder(courseID);
      if (findedOrder) {
        this.handleExistingOrder(findedOrder);
        return;
      }
      try {
        const { data } = await payment.makeOrder(courseID);
        this.orders.push(data.id);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    handleOrder() {
      if (!this.isLogged) {
        this.$store.dispatch('notification/spawn', {
          type: 'warning',
          title: this.$t('warning'),
          text: this.$t('checkout.errorText'),
        });

        this.$router.push('/auth');
      } else if (!this.isDataFilled) {
        this.$store.dispatch('notification/spawn', {
          type: 'warning',
          title: this.$t('warning'),
          text: this.$t('checkout.fillData'),
        });
        this.$router.push('/settings');
      } else {
        this.$analytics.fbq.event('InitiateCheckout');
        this.coursesIDs.forEach((courseID) => {
          this.makeOrderRequest(courseID);
        });
      }
    },
    async makeOrderPay() {
      const formattedIDs = [...new Set(this.orders)].join(',');
      // this.$store.dispatch('ui/setPreloader', true);

      try {
        const { data } = await payment.makePayment(formattedIDs, this.promoCode);
        const token = data.link.toString()
          .substr(-17);
        this.$refs.summary.setTransactionId(token);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          type: 'error',
          title: this.$t('checkout.errorTitle'),
          text: this.$t('checkout.errorText'),
        });
        this.$store.dispatch('ui/setPreloader', false);
        console.log(err);
        Sentry.captureException(err, {
          tags: {
            component: 'payments',
          },
        });
      }
    },
    findExistingOrder(id) {
      if (this.userCourses.length === 0) {
        return null;
      }
      const findedOrder = this.userCourses.find((order) => order.courseId === id);
      if (!findedOrder) {
        return null;
      }
      return findedOrder;
    },
    handleExistingOrder(order) {
      if (order.paymentStatus === 'waiting_for_payment') {
        this.orders.push(order.id);
      }
      if (order.paymentStatus === 'paid') {
        this.$store.dispatch('notification/spawn', {
          type: 'error',
          errorMessage: 'course_already_paid',
        });
      }
    },
  },
  async created() {
    const { data } = await course.getOwnedCourses();
    this.userCourses = data;
    const extra = await course.getExtraCourses();
    this.extraList = extra.data;
  },
};
</script>

<style lang="scss" scoped src="./Checkout.scss"/>
